/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import { AppContext } from "components/Context/AppContext"
import mapProductsFormAnalytics from "utils/mapProductsFormAnalytics"

import {
  ProductModal,
  ProductInfo,
  ProductGallery,
  ProductSpecificationShort,
  ProductSummary,
  ProductNav,
  ProductDescription,
  ProductSpecification,
  ProductOpinions,
  ProductRecentlyReviewed,
  ProductCartConfirmation,
} from "components/Product"

const Product = ({ pageContext }) => {
  const { data, allViewedProducts } = pageContext
  const title = data.name
  const [cart] = useContext(AppContext)

  const [modal, setModal] = useState(false)
  const [gallery, setGallery] = useState(false)
  const [cartConfirmation, setCartConfirmation] = useState(false)
  const [isLaptopCat, setIsLaptopCat] = useState(true)
  const [isAddedToCart, setIsAddedToCart] = useState(false)

  const productData = {
    title,
    modal,
    setModal,
    gallery,
    setGallery,
    cartConfirmation,
    setCartConfirmation,
    isLaptopCat,
    isAddedToCart,
    setIsAddedToCart,
  }
  useEffect(() => {
    const isBrowser = typeof window !== "undefined"

    data.productCategories.nodes.filter(item =>
      setIsLaptopCat(item.slug.includes("laptopy"))
    )
    if (isBrowser) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        ecommerce: {
          detail: {
            products: mapProductsFormAnalytics({ products: [data] }),
          },
        },
      })
      window.fbq("track", "ViewContent", {
        content_name: data.name,
        content_ids: [data.databaseId],
        content_type: "product",
      })
    }
  }, [
    data.productCategories.nodes,
    isAddedToCart,
    cart,
    data.databaseId,
    data.name,
    data.id,
    data.price,
  ])

  return (
    <Layout>
      <Seo
        title={data.seo.title ? data.seo.title : title}
        description={data.seo.metaDesc && data.seo.metaDesc}
      />

      <Breadcrumbs
        className="breadcrumbs--product"
        childOf={data.productCategories.nodes[0].name}
        title={title}
      />

      <ProductInfo {...productData} title={title} data={data} />

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-md-7">
              <ProductGallery {...productData} data={data} />
            </div>

            <div className="col-xl-6 col-md-5">
              <div className="row flex-column-reverse flex-xl-row">
                <div className="col-xl-6">
                  <ProductSpecificationShort {...productData} data={data} />
                </div>
                <div className="col-xl-6">
                  <ProductSummary {...productData} data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ProductNav />

      <span id="description" />
      <ProductDescription data={data} />

      <span id="specification" />
      <ProductSpecification {...productData} data={data} />

      <span id="opinions" />
      <ProductOpinions data={data} />

      <ProductRecentlyReviewed
        {...productData}
        data={data}
        allViewedProducts={allViewedProducts}
      />

      {modal && <ProductModal {...productData} />}
      {cartConfirmation && <ProductCartConfirmation {...productData} />}
    </Layout>
  )
}

export default Product
